import { AuthenticatedState } from '@/utils/types';
import { CashPayTemplate } from './vob-templates/CashPayTemplate';
import { OutOfNetworkTemplate } from './vob-templates/OutOfNetworkTemplate';
import { InProgressTemplate } from './vob-templates/InProgressTemplate';

export interface BillingEstimatesProps {
  insurance: Extract<
    AuthenticatedState['data']['insurance'],
    { loadingState: 'done' }
  >;
}

export const BillingEstimates = (props: BillingEstimatesProps) => {
  const { insurance } = props;

  if (!insurance.benefits) return null;
  const billingEstimates = insurance.benefits;

  let template = null;

  switch (billingEstimates.billing_estimate_template) {
    case 'cash_pay':
      template = <CashPayTemplate billingEstimates={billingEstimates} />;
      break;
    case 'vob_summary_oon':
      template = <OutOfNetworkTemplate billingEstimates={billingEstimates} />;
      break;
    case 'in_progress':
      template = <InProgressTemplate />;
      break;
  }
  return (
    <div className="p-6 border-1 border-tertiary-2 rounded">{template}</div>
  );
};
