import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useData from '@/state/use-data';
import BackNavigation from '@/components/BackNavigation';
import Button from '@/components/Button';
import Text from '@/components/Text';
import {
  ActiveTherapyType,
  AnalyticsEvent,
  DeactivationEvent,
  ReactChildren,
} from '@/utils/types';
import trackEvent from '@/utils/amplitude';
import { getNextActiveAppointmentV2 } from '@/utils/dates';
import WithProviderFromCareTeamV2 from '../WithProviderFromCareTeamV2';
import Dropdown from '@/components/Dropdown';
import { capitalize } from '@/utils/format';
import { MobileContext } from '@/App';
import { ProviderWithFirstAppointmentInfo } from '@/utils/types';
import RedirectToCareTeamOnErrorV2 from '../RedirectToCareTeamOnErrorV2';
import SupportLink from '@/components/SupportLink';
import { useFlagCheck } from '@/utils/use-feature-flags';
import { supportEmailInPerson } from '@/utils/constants';

const StepCard = ({
  image,
  step,
  header,
  description,
}: {
  image: ReactChildren;
  step: number;
  header: string;
  description: string;
}) => {
  return (
    <div className="flex-auto rounded-2 border-1 border-tertiary-2 bg-quaternary-0 p-8 basis-[344px]">
      <div className="w-25 h-25 flex items-center mb-3">{image}</div>
      <Text.Small.Bold className="mb-1">Step {step}</Text.Small.Bold>
      <Text.H4 className="mb-2">{header}</Text.H4>
      <Text.P>{description}</Text.P>
    </div>
  );
};

const Step1Image = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="81"
    height="63"
    viewBox="0 0 81 63"
    fill="none"
  >
    <circle
      cx="22.8532"
      cy="22.8532"
      r="22.3532"
      fill="#FBFAFA"
      stroke="#073429"
    />
    <path
      d="M30.7328 13.3312C30.7328 17.9145 27.1843 21.5916 22.8533 21.5916C18.5222 21.5916 14.9738 17.9145 14.9738 13.3312C14.9738 8.74793 18.5222 5.0708 22.8533 5.0708C27.1843 5.0708 30.7328 8.74793 30.7328 13.3312Z"
      fill="#FBFAFA"
      stroke="#073429"
    />
    <path
      d="M12.3344 37.3994C9.48607 35.4678 9.0025 31.4716 11.3799 29.0244C14.3318 25.9859 18.3841 24.1147 22.8533 24.1147C27.3225 24.1147 31.3748 25.9859 34.3267 29.0244C36.7041 31.4716 36.2205 35.4678 33.3722 37.3994C30.3558 39.4451 26.7406 40.6356 22.8533 40.6356C18.966 40.6356 15.3508 39.4451 12.3344 37.3994Z"
      fill="#FBFAFA"
      stroke="#073429"
    />
    <circle
      cx="53.987"
      cy="36.1013"
      r="24.8532"
      fill="#FBFAFA"
      stroke="#FBFAFA"
      strokeWidth="4"
    />
    <circle
      cx="53.987"
      cy="36.1013"
      r="22.3532"
      fill="#FBFAFA"
      stroke="#073429"
    />
    <path
      d="M61.8666 26.5793C61.8666 31.1625 58.3181 34.8397 53.9871 34.8397C49.656 34.8397 46.1075 31.1625 46.1075 26.5793C46.1075 21.996 49.656 18.3188 53.9871 18.3188C58.3181 18.3188 61.8666 21.996 61.8666 26.5793Z"
      fill="#FBFAFA"
      stroke="#073429"
    />
    <path
      d="M43.4681 50.6475C40.6199 48.7159 40.1363 44.7197 42.5137 42.2725C45.4656 39.2339 49.5179 37.3628 53.9871 37.3628C58.4563 37.3628 62.5085 39.2339 65.4604 42.2725C67.8379 44.7197 67.3543 48.7159 64.506 50.6475C61.4896 52.6931 57.8744 53.8836 53.9871 53.8836C50.0997 53.8836 46.4846 52.6931 43.4681 50.6475Z"
      fill="#FBFAFA"
      stroke="#073429"
    />
    <path
      d="M67.7148 20.1424C67.7148 16.5917 70.5926 13.7139 74.1434 13.7139C77.6942 13.7139 80.572 16.5917 80.572 20.1424C80.572 23.6932 77.6942 26.571 74.1434 26.571C70.5926 26.571 67.7148 23.6932 67.7148 20.1424ZM77.0513 19.0325C77.3251 18.7588 77.3251 18.3118 77.0513 18.0381C76.7776 17.7644 76.3306 17.7644 76.0569 18.0381L73.3398 20.7552L72.2299 19.6452C71.9562 19.3715 71.5092 19.3715 71.2355 19.6452C70.9618 19.9189 70.9618 20.3659 71.2355 20.6396L72.8426 22.2468C73.1164 22.5205 73.5633 22.5205 73.8371 22.2468L77.0513 19.0325Z"
      fill="#073429"
    />
    <path
      d="M36 6.85693C36 3.54287 38.8778 0.856934 42.4286 0.856934C45.9794 0.856934 48.8571 3.54287 48.8571 6.85693C48.8571 10.171 45.9794 12.8569 42.4286 12.8569C38.8778 12.8569 36 10.171 36 6.85693ZM45.3365 5.821C45.6102 5.56553 45.6102 5.14834 45.3365 4.89287C45.0628 4.6374 44.6158 4.6374 44.3421 4.89287L41.625 7.42881L40.5151 6.39287C40.2414 6.1374 39.7944 6.1374 39.5206 6.39287C39.2469 6.64834 39.2469 7.06553 39.5206 7.321L41.1278 8.821C41.4015 9.07646 41.8485 9.07646 42.1222 8.821L45.3365 5.821Z"
      fill="#073429"
    />
  </svg>
);

const Step2Image = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
  >
    <circle
      cx="34"
      cy="34"
      r="32"
      fill="#FBFAFA"
      stroke="#FBFAFA"
      strokeWidth="4"
    />
    <circle cx="34" cy="34" r="29.5" fill="#FBFAFA" stroke="#073429" />
    <path
      d="M44.5002 21.5C44.5002 27.5963 39.7785 32.5 34.0002 32.5C28.222 32.5 23.5002 27.5963 23.5002 21.5C23.5002 15.4037 28.222 10.5 34.0002 10.5C39.7785 10.5 44.5002 15.4037 44.5002 21.5Z"
      fill="#FBFAFA"
      stroke="#073429"
    />
    <path
      d="M18.5994 52.1216C15.8801 49.9688 15.3989 46.0444 17.5868 43.3799C21.5506 38.5527 27.4352 35.5 33.9999 35.5C40.5645 35.5 46.4491 38.5527 50.4129 43.3799C52.6008 46.0444 52.1197 49.9688 49.4004 52.1216C45.1397 55.4946 39.7986 57.5 33.9999 57.5C28.2012 57.5 22.86 55.4946 18.5994 52.1216Z"
      fill="#FBFAFA"
      stroke="#073429"
    />
  </svg>
);

const Step3Image = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="66"
    viewBox="0 0 64 66"
    fill="none"
  >
    <rect
      x="8.26672"
      y="5.75732"
      width="8.06122"
      height="8.2"
      rx="1.5"
      fill="#073429"
    />
    <rect
      x="8.26672"
      y="5.75732"
      width="8.06122"
      height="8.2"
      rx="1.5"
      stroke="#073429"
    />
    <rect
      width="31.0671"
      height="0.657143"
      transform="translate(25.2419 9.85693)"
      fill="#073429"
    />
    <path
      d="M9.7085 12.4857L12.2974 9.85709M14.8863 7.22852L12.2974 9.85709M12.2974 9.85709L9.7085 7.22852M12.2974 9.85709L14.8863 12.4857"
      stroke="#FAFBFA"
    />
    <rect
      x="0.5"
      y="0.5"
      width="62.4286"
      height="18.7143"
      rx="3.5"
      stroke="#073429"
    />
    <rect
      x="8.26672"
      y="28.8999"
      width="8.06122"
      height="8.2"
      rx="1.5"
      fill="#073429"
    />
    <rect
      x="8.26672"
      y="28.8999"
      width="8.06122"
      height="8.2"
      rx="1.5"
      stroke="#073429"
    />
    <rect
      width="31.0671"
      height="0.657143"
      transform="translate(25.2419 32.9995)"
      fill="#073429"
    />
    <path
      d="M9.7085 35.6282L12.2974 32.9997M14.8863 30.3711L12.2974 32.9997M12.2974 32.9997L9.7085 30.3711M12.2974 32.9997L14.8863 35.6282"
      stroke="#FAFBFA"
    />
    <rect
      x="0.5"
      y="23.6426"
      width="62.4286"
      height="18.7143"
      rx="3.5"
      stroke="#073429"
    />
    <rect
      x="8.26672"
      y="52.043"
      width="8.06122"
      height="8.2"
      rx="1.5"
      fill="#073429"
    />
    <rect
      x="8.26672"
      y="52.043"
      width="8.06122"
      height="8.2"
      rx="1.5"
      stroke="#073429"
    />
    <rect
      width="31.0671"
      height="0.657143"
      transform="translate(25.2419 56.1426)"
      fill="#073429"
    />
    <path
      d="M9.7085 58.7713L12.2974 56.1427M14.8863 53.5142L12.2974 56.1427M12.2974 56.1427L9.7085 53.5142M12.2974 56.1427L14.8863 58.7713"
      stroke="#FAFBFA"
    />
    <rect
      x="0.5"
      y="46.7856"
      width="62.4286"
      height="18.7143"
      rx="3.5"
      stroke="#073429"
    />
  </svg>
);

const InitialReplacementPromptV2 = () => {
  const { providerNpi } = useParams<{
    providerNpi: string;
  }>();
  const { WithData, data } = useData(['appointments_v2']);
  const navigate = useNavigate();
  const mobile = useContext(MobileContext);

  const { isAllowed } = useFlagCheck();
  const inPersonEnabled = isAllowed({
    patientPortalReleaseInPersonVisits: true,
  });

  return (
    <WithProviderFromCareTeamV2 providerNpi={providerNpi}>
      {({ provider }) => (
        <WithData data={data}>
          {({ appointments_v2: appointments }) => {
            if (!provider) {
              return <RedirectToCareTeamOnErrorV2 addCare />;
            }
            const { npi } = provider as ProviderWithFirstAppointmentInfo;
            const originalAppointmentWithProvider = useMemo(
              () =>
                getNextActiveAppointmentV2(appointments.rows, provider?.npi),
              [],
            );
            let currentActiveCareTypes: { key: string; value: string }[] = [];

            const mapCareTypes = ({
              therapy_type,
            }: ActiveTherapyType | DeactivationEvent): {
              key: string;
              value: string;
            } => ({
              key: capitalize(therapy_type),
              value: capitalize(therapy_type),
            });

            if (provider && 'active_therapy_types' in provider) {
              currentActiveCareTypes =
                provider.active_therapy_types.map(mapCareTypes);
            } else if (provider && 'deactivation_events' in provider) {
              currentActiveCareTypes =
                provider.deactivation_events.map(mapCareTypes);
            }

            const currentActiveCareTypeOptions = [
              ...currentActiveCareTypes,
              {
                key: 'Choose replacement for...',
                value: '',
              },
            ];

            const hasOneCareType = currentActiveCareTypes.length === 1;

            const [therapyTypeToReplace, setTherapyTypeToReplace] =
              useState<string>(
                hasOneCareType ? currentActiveCareTypeOptions[0].value : '',
              );

            useEffect(() => {
              const rematchLandingPageEvent: AnalyticsEvent = {
                product_area: 'RematchPage',
                name: 'page_view',
                trigger: 'Page load',
                metadata: {
                  provider_npi: provider?.npi.toString() as string,
                  original_appointment_time_local:
                    originalAppointmentWithProvider?.start_time ?? null,
                },
              };

              trackEvent(rematchLandingPageEvent);
            }, []);

            const handleBeginReplacement = () => {
              if (therapyTypeToReplace) {
                navigate(
                  `/care-team/${npi}/${therapyTypeToReplace.toLocaleLowerCase()}/replace/find-new-provider`,
                );
              }
            };

            const secondaryCopy =
              'You can replace your provider in just a few steps';

            const SupportPhoneLink = () => (
              <a
                role="button"
                className="no-underline text-primary-3"
                href="tel:12136421035"
              >
                call (213) 642-1035
              </a>
            );

            return (
              <div>
                <div className="mb-5 flex">
                  <BackNavigation />
                </div>
                <div className="flex flex-col justify-center items-center w-full min-h-[305px] md:min-h-[400px] bg-amethyst mb-[50px] p-8 rounded-2 md:w-auto">
                  <div className="space-y-3 mb-8 text-center">
                    <div className="font-medium">
                      <Text.H1>Let's replace your provider</Text.H1>
                    </div>
                    <Text.P>
                      Many people meet with more than one provider before
                      finding the right fit.
                    </Text.P>
                  </div>
                  <div className="flex gap-3 flex-wrap justify-center px-5 md:w-[660px] md:px-0">
                    {hasOneCareType ? null : (
                      <div className="basis-full md:basis-2/3">
                        <Dropdown
                          options={currentActiveCareTypeOptions}
                          value={therapyTypeToReplace}
                          onChange={setTherapyTypeToReplace}
                        />
                      </div>
                    )}
                    <div className="basis-full md:basis-auto">
                      <Button
                        variant="primary"
                        onClick={handleBeginReplacement}
                        state={therapyTypeToReplace ? '' : 'disabled'}
                      >
                        Begin Replacement
                      </Button>
                    </div>
                  </div>
                  {inPersonEnabled ? (
                    <div className="text-center mt-8">
                      <Text.P>
                        To rematch with an in-person provider, please reach out
                        to{' '}
                        <SupportLink
                          email={supportEmailInPerson}
                          text={supportEmailInPerson}
                        />{' '}
                        or <SupportPhoneLink />
                      </Text.P>
                    </div>
                  ) : null}
                </div>
                {mobile ? (
                  <Text.H3>{secondaryCopy}</Text.H3>
                ) : (
                  <Text.H2 className="mb-8">{secondaryCopy}</Text.H2>
                )}

                <div className="w-full flex gap-7 flex-wrap">
                  <StepCard
                    step={1}
                    header="Explore provider matches"
                    description="Available providers who accept your insurance or preferred payment."
                    image={<Step1Image />}
                  ></StepCard>
                  <StepCard
                    step={2}
                    header="Choose a new provider"
                    description="Choose your partner in care and book your first appointment."
                    image={<Step2Image />}
                  ></StepCard>
                  <StepCard
                    step={3}
                    header="Replace your existing provider"
                    description="Remaining appointments with your existing provider will be cancelled."
                    image={<Step3Image />}
                  ></StepCard>
                </div>
              </div>
            );
          }}
        </WithData>
      )}
    </WithProviderFromCareTeamV2>
  );
};

export default InitialReplacementPromptV2;
