import { useContext, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { StateContext } from '@/App';
import useData from '@/state/use-data';
import actions from '@/state/actions';
import Text from '@/components/Text';
import {
  ActiveProviderShapeV3,
  AnalyticsEvent,
  InactiveProviderShape,
  JSONValue,
  ProviderProfileParams,
  Trigger,
} from '@/utils/types';
import RedirectToAppointmentsOnError from '@/pages/appointments/RedirectToAppointmentsOnError';
import { getBookableSlotsForProvider } from '@/utils/appointments';
import trackEvent from '@/utils/amplitude';
import { getSlotsInNextSevenDays } from '@/utils/dates';
import ChooseAppointment from '@/components/appointments/ChooseAppointment';
import { sendMessage } from '@/utils/api';

const ChooseAppointmentWithoutPrevious = () => {
  const { providerNpi, therapyType } = useParams<ProviderProfileParams>();

  const { state } = useContext(StateContext);
  const pageState = state.ui.page;

  const { WithData, data } = useData([
    'careTeam_v3',
    'messaging',
    'patientData',
  ]);

  if (pageState.path !== 'appointments') {
    return null;
  }

  return (
    <WithData data={data}>
      {({ careTeam_v3: careTeam, messaging, patientData }) => {
        const { dispatch } = useContext(StateContext);
        const [searchParams] = useSearchParams();
        const isDeeplink = !!searchParams.get('deeplink');
        const isReactivation = !!searchParams.get('reactivate');

        const npi = Number(providerNpi);
        let foundProvider:
          | ActiveProviderShapeV3
          | InactiveProviderShape
          | undefined = careTeam.active.find((p) => {
          return p?.npi === npi;
        });

        if (isReactivation && !foundProvider) {
          // if provider isn't found in active care team, can be inactive if allow reactivation flag is set
          foundProvider = careTeam.inactive.find((p) => {
            return p?.npi === npi;
          });
        }

        if (!providerNpi || Number.isNaN(npi) || foundProvider === undefined) {
          console.error('provider npi not on active care team');
          return <RedirectToAppointmentsOnError />;
        }
        // added because typescript wasn't recognizing the undefined check above and errored on accessing provider properties below
        const provider: ActiveProviderShapeV3 | InactiveProviderShape =
          foundProvider;

        if (!therapyType) {
          console.error('please provide a therapy type');
          return <RedirectToAppointmentsOnError />;
        }

        const { first_name, last_name } = provider;

        const seriesType = 'followup';
        const bookableSlots = getBookableSlotsForProvider(
          state.ui.usedAvailabilitySlots,
          provider,
          therapyType,
          seriesType,
          false,
        );

        const hasBookableAppointments =
          bookableSlots && bookableSlots.length > 0;

        const appointmentLengthInMinutes =
          therapyType === 'psychiatric' ? 30 : 60;

        const trackBookNewFollowupEvent = (
          name: string,
          trigger: Trigger,
          metadata: { [key: string]: JSONValue } = {},
        ) => {
          const event: AnalyticsEvent = {
            product_area: 'Appointments',
            name,
            trigger,
            metadata: {
              provider_name: `${first_name} ${last_name}`,
              provider_npi: providerNpi,
              treatment_type:
                therapyType === 'psychiatric' ? 'psychiatry' : 'therapy',
              ...metadata,
            },
          };
          trackEvent(event);
        };

        useEffect(() => {
          const sessionsWithin7Days = getSlotsInNextSevenDays(bookableSlots);
          trackBookNewFollowupEvent(
            'schedule_follow_up_page_view',
            'Page load',
            {
              patient_app_linked_from_email: isDeeplink,
              available_sessions_count: bookableSlots.length,
              available_sessions_within_7_days_count: sessionsWithin7Days,
            },
          );
          if (!hasBookableAppointments) {
            trackEvent({
              product_area: 'Appointments',
              name: 'no_availability',
              trigger: 'Page load',
              metadata: {
                therapy_type: therapyType,
                provider_npi: providerNpi,
              },
            });
          }
        }, []);

        const messageThread = messaging.threads.find(
          (thread) => thread.providerUuid === provider.uuid,
        );

        const onSendMessage = async (messageText: string) => {
          const thread: {
            message: string;
            patient_uuid: string;
            provider_uuid: string;
            thread_id?: string;
          } = {
            message: messageText,
            patient_uuid: patientData.patient_uuid,
            provider_uuid: provider.uuid,
          };

          if (messageThread && messageThread.uuid) {
            thread.thread_id = messageThread.uuid;
          }

          try {
            const res = await sendMessage(thread);

            if (!res.messageThreadUuid) {
              throw new Error(
                'Error getting message thread uuid from response',
              );
            }
            trackEvent({
              product_area: 'Appointments',
              name: 'message_sent',
              trigger: 'Interaction',
              metadata: {
                therapy_type: therapyType,
                provider_npi: provider.npi,
              },
            });
            dispatch(
              actions.async.setLoading({
                key: 'messaging',
                loadingState: 'needed',
              }),
            );
            dispatch(
              actions.setToast({
                text: <Text.P.Inline>Message Sent!</Text.P.Inline>,
                variant: 'success',
                onClose: () => dispatch(actions.setToast(null)),
              }),
            );
          } catch (e) {
            dispatch(
              actions.setToast({
                text: (
                  <Text.P.Inline>
                    Message failed to send. Please try again.
                  </Text.P.Inline>
                ),
                variant: 'warning',
                onClose: () => dispatch(actions.setToast(null)),
              }),
            );
            console.error(e);
          }
        };

        return (
          <ChooseAppointment
            provider={provider}
            hasBookableAppointments={hasBookableAppointments}
            therapyType={therapyType}
            seriesType={seriesType}
            isReactivation={isReactivation}
            isHold={false}
            appointmentLengthInMinutes={appointmentLengthInMinutes}
            onSendMessage={onSendMessage}
          />
        );
      }}
    </WithData>
  );
};

export default ChooseAppointmentWithoutPrevious;
