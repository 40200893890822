import { useProvider } from '@/utils/use-provider';
import { ProviderDetail } from '@/utils/types';
import ErrorPage from '@/pages/ErrorPage';
import Loader from '@/components/Loader';

type Props = {
  children: (provider: ProviderDetail) => JSX.Element;
  passthrough?: ProviderDetail;
  patientRecordUuid: string;
  providerNpi: string;
};

export function WithProvider({
  children,
  passthrough,
  providerNpi,
  patientRecordUuid,
}: Props) {
  const [provider, status] = useProvider({
    providerNpi,
    patientRecordUuid,
    skip: !!passthrough,
  });

  if (passthrough) {
    return children(passthrough);
  }

  if (provider) {
    return children(provider);
  }

  if (status === 'error') {
    return <ErrorPage />;
  }

  return <Loader.FullScreen />;
}
