import { UnauthenticatedUi } from '@/utils/types';
import Text from '@/components/Text';
import { Dispatch, useEffect, useState } from 'react';
import actions, { Action } from '@/state/actions';
import { useLoginAfterVerification } from '@/utils/session';
import fetch from '@/utils/fetch';
import { isMmDdYyyy, parseMmDdYyyy } from '@/utils/dates';
import dayjs from 'dayjs';
import DateInput from '@/components/DateInput';
import Button from '@/components/Button';
import SupportLink from '@/components/SupportLink';
import trackEvent from '@/utils/amplitude';

export interface VerifyDobProps {
  state: Extract<
    UnauthenticatedUi,
    { status: 'unverified' | 'unverified_phone' | 'unverified_birthdate' }
  >;
  dispatch: Dispatch<Action>;
  token: string | null;
}

const maxAttempts = 3;

const VerifyDob = ({ state, dispatch, token }: VerifyDobProps) => {
  const { dob = '', state: successState, attempts = [] } = state;
  const [formatError, setFormatError] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const loginAfterVerification = useLoginAfterVerification(dispatch);

  const success = successState === 'success';
  useEffect(() => {
    if (success) {
      setTimeout(loginAfterVerification, 2000);
    }
  }, [success]);

  useEffect(() => {
    if (isMmDdYyyy(dob) && formatError) {
      setFormatError(undefined);
      setError(undefined);
    }
  }, [dob]);

  const errorMessage = formatError || error;

  const verify = () => {
    if (!isMmDdYyyy(dob)) {
      setFormatError(
        `Please enter your date of birth in mm/dd/yyyy format, like ${dayjs().format(
          'MM/DD/YYYY',
        )}.`,
      );
      return;
    }
    if (successState === 'checking') {
      return;
    }
    dispatch(actions.verify.setState('checking'));
    fetch
      .json('/session/verify_dob', {
        method: 'PUT',
        body: {
          dob: parseMmDdYyyy(dob).format('YYYY-MM-DD'),
          token,
        },
      })
      .then(() => {
        dispatch(actions.verify.setState('success'));
        trackEvent({
          product_area: 'Verification',
          name: 'Patient_account_verified',
          trigger: 'Interaction',
        });
      })
      .catch((r) => {
        if ('status' in r && r.status === 401) {
          dispatch(actions.setSessionStatus('magic_link_expired'));
          return;
        }
        dispatch(actions.verify.setState());
        const attemptsLeft = maxAttempts - (attempts.length + 1);
        dispatch(actions.verify.pushErrorAttempt(dob));
        setError(
          `The date of birth you entered does not match our records. You have ${attemptsLeft} more attempt${
            attemptsLeft === 1 ? '' : 's'
          }.`,
        );
        if (attemptsLeft === 0) {
          trackEvent({
            product_area: 'Verification',
            name: 'Patient_verification_failed',
            trigger: 'Interaction',
          });
        }
      });
  };

  const getVerifyButtonState = () => {
    if (successState === 'checking') {
      return 'waiting';
    }
    if (!dob) {
      return 'disabled';
    }
    return '';
  };

  const easeOut = 'transition ease-out opacity-0 duration-1000';
  const easeIn = 'transition ease-in opacity-100 duration-1000';
  return (
    <div className="flex flex-col justify-center h-full">
      <div
        className={`${
          success || attempts.length >= maxAttempts ? easeOut : 'z-10'
        } flex w-full align-center justify-center`}
      >
        <div className="space-y-6 min-w-[250px]">
          <Text.Serif.H1 className="flex justify-center align-center text-center">
            Confirm your date of birth
          </Text.Serif.H1>
          <div className="space-y-4 w-1/2 ml-auto mr-auto min-w-[250px]">
            <div className="flex flex-col space-y-2">
              <Text.Small.Bold>Date of birth</Text.Small.Bold>
              <DateInput
                value={dob}
                state={errorMessage ? 'error' : ''}
                placeholder={errorMessage ? attempts[0] : 'MM/DD/YYYY'}
                onEnter={verify}
                onChange={(dob) => dispatch(actions.verify.setDob(dob))}
              />
            </div>
            {errorMessage && (
              <p className="rounded border-warning-1 border-1 bg-opacity-10 bg-warning-1 text-warning-1 p-3 text-center">
                {errorMessage}
              </p>
            )}
            <Button
              variant="secondary"
              onClick={verify}
              disabled={successState === 'checking' || !dob}
              state={getVerifyButtonState()}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
      <div className="absolute left-1/2 -translate-x-1/2 text-center">
        <div className={success ? easeIn : 'opacity-0'}>
          <Text.Serif.H1 className="font-bold flex justify-center">
            Success!
          </Text.Serif.H1>
        </div>
      </div>
      <div className="absolute w-full left-1/2 -translate-x-1/2 text-center">
        <div
          className={`${
            attempts.length >= maxAttempts ? easeIn : 'opacity-0'
          } flex flex-col gap-8`}
        >
          <Text.Serif.H1 className="font-bold flex justify-center">
            Unable to verify your account
          </Text.Serif.H1>
          <Text.P.Bold>
            The date of birth you entered does not match our records.
          </Text.P.Bold>
          <Text.P.Bold>
            Please contact us at <SupportLink /> to verify your account.
          </Text.P.Bold>
        </div>
      </div>
    </div>
  );
};

export default VerifyDob;
