import InfoCircle from '@/components/icons/InfoCircle';

export const InProgressTemplate = () => {
  return (
    <div className="space-y-4">
      <div className="font-medium text-lg text-tertiary-5 flex items-center gap-2.5">
        <InfoCircle />
        Your insurance is being verified
      </div>
      <div className="flex gap-6">
        <div className="bg-tertiary-1 rounded-2 px-9 py-6 space-y-4 min-w-[328px]">
          <div className="text-xs text-tertiary-5">COST ESTIMATE</div>
          <hr />
          <div>
            <div className="text-sm text-tertiary-5">In progress...</div>
          </div>
        </div>
        <div className="pl-9 pr-6 text-tertiary-5">
          We're working on verifying your insurance and sharing your cost
          estimate. We'll email you the results as soon as the verification is
          complete.
        </div>
      </div>
    </div>
  );
};
