import InfoCircle from '@/components/icons/InfoCircle';
import { BillingEstimates } from '@/utils/types';

interface OutOfNetworkTemplateProps {
  billingEstimates: BillingEstimates;
}

export const OutOfNetworkTemplate = ({
  billingEstimates,
}: OutOfNetworkTemplateProps) => {
  return (
    <div className="space-y-4">
      <div className="font-medium text-lg text-tertiary-5 flex items-center gap-2.5">
        <InfoCircle />
        Unfortunately, Rula appointments aren't covered by your insurance
      </div>
      <div className="flex gap-6">
        <div className="bg-tertiary-1 rounded-2 px-9 py-6 space-y-4 min-w-[328px]">
          <div className="text-xs text-tertiary-5">COST ESTIMATE</div>
          <div>
            <div className="text-sm text-tertiary-5">Cash pay</div>
            <div className="text-primary-3 text-2xl font-medium">
              ${billingEstimates.pre_deductible_estimate}
            </div>
          </div>
        </div>
        <div className="pl-9 pr-6 text-tertiary-5">
          <p>
            After careful review, we've determined that Rula appointments are
            not covered by your insurance. We sincerely apologize for any
            inconvenience.
            <br />
            <br />
            If you'd like to proceed without insurance, Rula offers a self-pay
            option that may be eligible for FSA/HSA reimbursement.
            <br />
            <br />
            <b className="mb-2 font-medium text-tertiary-7">
              If you're new to Rula
            </b>
            <br />
            If you recently scheduled your first appointment and would like to
            proceed with the self-pay rate, please send an email to{' '}
            <a
              className="text-primary-3 no-underline"
              href="mailto:support@rula.com"
            >
              support@rula.com
            </a>{' '}
            or call (323) 745-8323. If we do not hear from you, we will
            automatically cancel your scheduled appointment(s) 24 hr prior to
            your first visit.
            <br />
            <br />
            <b className="mb-2 font-medium text-tertiary-7">
              If you're an existing patient
            </b>
            <br />
            You will be responsible for paying the self-pay rate for future
            appointments. If you need to cancel your upcoming appointments,{' '}
            <a className="text-primary-3 no-underline" href="/appointments">
              click here
            </a>
            .
            <br /> <br />
            <b className="mb-2 font-medium text-tertiary-7">
              This breakdown is just an estimate
            </b>
            <br />
            This estimate is based on the information you provided to Rula. If
            it isn't what you expect, contact your insurer for clarification and{' '}
            <a
              className="text-primary-3 no-underline"
              href="https://patientsupport.rula.com/hc/en-us/sections/27330239424411-Your-health-insurance-pre-visit"
            >
              check out our insurance FAQ
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};
