import fetch from '@/utils/fetch';
import { ProviderDetail } from '@/utils/types';

export const getProviderDetails = async (
  npi: number,
  patient_record_uuid: string,
  new_provider_search = true,
): Promise<ProviderDetail> => {
  return fetch.json(
    `/api/provider?${new URLSearchParams({
      provider_npi: `${npi}`,
      patient_record_uuid,
      new_provider_search: (!!new_provider_search).toString(),
    })}`,
    {
      method: 'GET',
    },
  );
};

export const isOfferingInPerson = async (providerUuid: string) => {
  return fetch
    .json(
      `/api/provider_offers_in_person?${new URLSearchParams({
        provider_uuid: `${providerUuid}`,
      })}`,
      {
        method: 'GET',
      },
    )
    .then((r) => {
      return r.offering_in_person;
    })
    .catch(() => {
      return Promise.resolve(false);
    });
};
