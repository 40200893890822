import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { StateContext } from '@/App';
import { ReactChildren, TherapyType } from '../../utils/types';
import { useFlagCheck } from '@/utils/use-feature-flags';
import ChooseAppointmentLocationModal from '@/components/ChooseAppointmentLocationModal';
import actions from '@/state/actions';

export interface ReplaceProviderTriggerProps {
  providerNpi: number;
  children: (props: { onClick: () => unknown }) => ReactChildren;
  therapyType: TherapyType;
}

const ReplaceProviderTrigger = ({
  children,
  providerNpi,
  therapyType,
}: ReplaceProviderTriggerProps) => {
  const { dispatch } = useContext(StateContext);
  const navigate = useNavigate();
  const { isAllowed } = useFlagCheck();
  const inPersonEnabled = isAllowed({
    patientPortalReleaseInPersonVisits: true,
  });
  const navigateToReplace = () => {
    navigate(
      `/care-team/${providerNpi}/${therapyType}/replace/find-new-provider`,
    );
  };
  const onClick = () => {
    if (inPersonEnabled && therapyType !== 'psychiatric') {
      dispatch(
        actions.setModal({
          children: (
            <ChooseAppointmentLocationModal
              onTelemedicineSelect={navigateToReplace}
            />
          ),
        }),
      );
      return;
    }
    navigateToReplace();
  };
  return children({ onClick });
};

export default ReplaceProviderTrigger;
