import { useContext, useEffect, useRef } from 'react';
import { StateContext } from '@/App';
import Button from '@/components/Button';
import { TopNavContext } from '@/components/PageLayout';
import Text from '@/components/Text';
import actions from '@/state/actions';
import Section from './Section';

const EditSection = ({
  children,
  label,
  onSave,
  onCancel,
  invalid,
  validationErrors,
  saving,
  showButtons = true,
  fullWidth = false,
}: {
  children: null | JSX.Element | (null | JSX.Element)[];
  label: string;
  onSave: () => false | Promise<unknown>; // false if save doesn't happen due to validation
  onCancel: () => unknown;
  invalid: boolean;
  saving: boolean;
  validationErrors: string[];
  showButtons?: boolean;
  fullWidth?: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const topNav = useContext(TopNavContext);
  const { dispatch } = useContext(StateContext);
  useEffect(() => {
    if (invalid) {
      const y = containerRef.current?.getBoundingClientRect().y;
      if (y && y < 0) {
        const top =
          window.scrollY + y - (topNav?.getBoundingClientRect().height || 0);
        window.scrollTo({
          behavior: 'smooth',
          top,
        });
      }
    }
  }, [invalid]);

  const save = () => {
    const result = onSave();
    if (result === false) {
      return;
    }
    void result.then(() =>
      dispatch(
        actions.setToast({
          variant: 'success',
          text: <Text.P>Your changes have been saved!</Text.P>,
          timeout: 3000,
          onClose: () => dispatch(actions.setToast(null)),
        }),
      ),
    );
  };

  const getSaveButtonState = () => {
    if (invalid) {
      return 'disabled';
    }
    return saving ? 'waiting' : '';
  };

  return (
    <Section ref={containerRef} fullWidth={fullWidth}>
      {invalid && (
        <div className="rounded border-warning-1 border-1 w-full p-4">
          <Text.P.Bold>
            Oops! Some data you entered is not in the right format:
          </Text.P.Bold>
          <ul className="list-disc pl-4">
            {validationErrors.map((e, i) => (
              <li key={i}>
                <Text.P>{e}</Text.P>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="flex justify-between items-center">
        <Text.P.Bold>{label}</Text.P.Bold>
      </div>
      {children}
      {showButtons && (
        <div className="flex justify-end space-x-2">
          <Button onClick={onCancel} variant="flat">
            Cancel
          </Button>
          <Button onClick={save} state={getSaveButtonState()} variant="primary">
            Save
          </Button>
        </div>
      )}
    </Section>
  );
};

export default EditSection;
