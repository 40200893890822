import { useContext, useEffect, useState } from 'react';
import actions from './state/actions';
import { StateContext } from './App';
import Avatar from './components/Avatar';
import Chevron from './components/icons/Chevron';
import Text from './components/Text';
import { makeCorrectCasing } from './utils/format';
import { useLogout } from './utils/session';
import stopPropagation from './utils/stop-propagation';
import { Loaded, ProfileDetails, isLoaded } from './utils/types';
import switchAccount from '@/utils/switch_account';

const UserMenu = () => {
  const { state, dispatch } = useContext(StateContext);
  const { profile } = state.data;
  // cache name information so reloading the profile doesn't cause avatar and menu to go away
  const [nameInfo, setNameInfo] = useState({
    initials: '',
    first_name: '',
    last_name: '',
    preferred_name: '',
  });

  useEffect(() => {
    if (isLoaded(profile)) {
      setNameInfo({
        initials:
          profile.preferred_name[0] ||
          profile.first_name[0] + profile.last_name[0],
        first_name: profile.first_name,
        last_name: profile.last_name,
        preferred_name: profile.preferred_name,
      });
    }
  }, [
    profile.loadingState,
    (profile as Loaded<ProfileDetails>).preferred_name,
    // Preferred name may change in the profile details section. We want the UserMenu to live-update when it is changed.
  ]);

  const { userMenuOpen } = state.ui;
  const logout = useLogout(dispatch);

  const renderUserMenu = () => {
    const getChevronRotationClass = () => {
      return userMenuOpen ? 'rotate-180' : '';
    };

    if (nameInfo.initials) {
      return (
        <div className="relative flex justify-end pr-8 items-center">
          <div
            className="flex items-center cursor-pointer"
            onClick={stopPropagation(() =>
              dispatch(actions.setUserMenuOpen(!userMenuOpen)),
            )}
          >
            <Avatar initials={nameInfo.initials} />
            <Text.P.Inline className="ml-3 text-tertiary-5">
              {makeCorrectCasing(
                nameInfo.preferred_name || nameInfo.first_name,
                true,
              )}{' '}
              {makeCorrectCasing(nameInfo.last_name, true)}
            </Text.P.Inline>
            <Chevron
              data-testid="user-menu-chevron"
              className={`w-8 h-4 ${getChevronRotationClass()}`}
              stroke="tertiary-3"
            />
          </div>
          {userMenuOpen && (
            <div className="absolute p-4 w-48 top-10 bg-tertiary-0 z-10 rounded-2 shadow-hover">
              <div
                className="cursor-pointer mb-3"
                onClick={async () => await switchAccount(dispatch)}
              >
                <Text.P className="hover:text-primary-3 hover:font-[500]">
                  Switch profile
                </Text.P>
              </div>
              <div className="cursor-pointer" onClick={stopPropagation(logout)}>
                <Text.P className="hover:text-primary-3 hover:font-[500]">
                  Log out
                </Text.P>
              </div>
            </div>
          )}
        </div>
      );
    }

    if (profile.loadingState === 'error') {
      return (
        <Text.P.Bold
          data-testid="user-menu-error-logout"
          onClick={logout}
          className="text-primary-3 cursor-pointer p-4 pr-12"
        >
          Log out
        </Text.P.Bold>
      );
    }

    return null;
  };

  return renderUserMenu();
};

UserMenu.Mobile = () => {
  const { state, dispatch } = useContext(StateContext);
  const { profile } = state.data;
  // cache name information so reloading the profile doesn't cause avatar and menu to go away
  const [nameInfo, setNameInfo] = useState({
    initials: '',
    first_name: '',
    last_name: '',
    preferred_name: '',
  });

  useEffect(() => {
    if (isLoaded(profile)) {
      setNameInfo({
        initials:
          profile.preferred_name[0] ||
          profile.first_name[0] + profile.last_name[0],
        first_name: profile.first_name,
        last_name: profile.last_name,
        preferred_name: profile.preferred_name,
      });
    }
  }, [
    profile.loadingState,
    (profile as Loaded<ProfileDetails>).preferred_name,
    // Preferred name may change in the profile details section. We want the UserMenu to live-update when it is changed.
  ]);

  const { userMenuOpen } = state.ui;
  const logout = useLogout(dispatch);

  return nameInfo ? (
    <div className="relative flex flex-col justify-end">
      <div
        className="relative flex justify-between items-center cursor-pointer"
        onClick={stopPropagation(() =>
          dispatch(actions.setUserMenuOpen(!userMenuOpen)),
        )}
      >
        <div className="relative flex items-center">
          <Avatar initials={nameInfo.initials} />
          <Text.P.Inline.Bold className="ml-3 text-tertiary-7">
            {makeCorrectCasing(
              nameInfo.preferred_name || nameInfo.first_name,
              true,
            )}{' '}
            {makeCorrectCasing(nameInfo.last_name, true)}
          </Text.P.Inline.Bold>
        </div>
        {userMenuOpen ? (
          <Chevron className="w-8 h-4 rotate-180" stroke="tertiary-3" />
        ) : (
          <Chevron className="w-8 h-4" stroke="tertiary-3" />
        )}
      </div>
      {userMenuOpen && (
        <div className="pt-3">
          <div
            className="cursor-pointer mb-3"
            onClick={async () => {
              await switchAccount(dispatch);
              dispatch(actions.setUserMenuOpen(false));
              dispatch(actions.setHamburgerMenuOpen(false));
            }}
          >
            <Text.P.Bold className="text-primary-3">Switch profile</Text.P.Bold>
          </div>
          <div className="cursor-pointer" onClick={stopPropagation(logout)}>
            <Text.P.Bold className="text-primary-3">Log out</Text.P.Bold>
          </div>
        </div>
      )}
    </div>
  ) : (
    <Text.P.Bold onClick={logout} className="text-primary-3 cursor-pointer p-4">
      Log out
    </Text.P.Bold>
  );
};

export default UserMenu;
