import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StateContext } from '@/App';
import useData from '@/state/use-data';
import {
  therapyTypeToAppointmentType,
  therapyTypeToVisitType,
  errorsCancelingHold,
  errorCreatingHold,
  errorsFindingOldAppointment,
  errorSlotNotAvailable,
  psychiatryProviderRole,
  toastSessionNotAvailable,
  toastDidntWorkTryAgain,
  toastErrorBookingSession,
} from '@/utils/constants';
import {
  RescheduleV2Params,
  AnalyticsEvent,
  Trigger,
  JSONValue,
  JSONObject,
  TherapyType,
  AppointmentV2,
} from '@/utils/types';
import RedirectToAppointmentsOnError from '@/pages/appointments/RedirectToAppointmentsOnError';
import Text from '@/components/Text';
import Dropdown from '@/components/Dropdown';
import Dollar from '@/components/icons/Dollar';
import {
  getBookableSlotsForProvider,
  getPatientFromParticipants,
} from '@/utils/appointments';
import User from '@/components/icons/User';
import Button from '@/components/Button';
import fetch from '@/utils/fetch';
import actions from '@/state/actions';
import { makeDollars } from '@/utils/format';
import { ToastProps } from '@/components/Toast';
import SupportLink from '@/components/SupportLink';
import PaymentText from '@/components/appointments/PaymentText';
import trackEvent from '@/utils/amplitude';
import {
  getAppointmentLengthAndEndTime,
  appointmentIsWithin24Hours,
  appointmentIsPast,
} from '@/utils/dates';
import useRefreshCareTeam from '@/utils/use-refresh-care-team';
import TherapyTypeText from '@/components/appointments/TherapyTypeText';
import { getProviderFromCareTeamByNpi } from '@/utils/provider';
import ConfirmAppointmentCardHeader from '@/components/appointments/ConfirmAppointmentCardHeader';
import ConfirmAppointmentPageHeader from '@/components/appointments/ConfirmAppointmentPageHeader';

// Only values allowed for followup appointments
const THERAPY_TYPE_OPTIONS = [
  {
    key: 'Select...',
    value: '',
    disabled: true,
  },
  {
    key: 'Individual',
    value: 'individual',
  },
  {
    key: 'Couples',
    value: 'couples',
  },
];

const ConfirmAppointmentV2 = () => {
  const { providerNpi, appointmentUUID, newAppointmentUnix } =
    useParams<RescheduleV2Params>();
  const navigate = useNavigate();

  // Load providers if provider data isn't in state
  const { state } = useContext(StateContext);
  const { dispatch } = useContext(StateContext);
  const pageState = state.ui.page;
  const [waiting, setWaiting] = useState(false);
  const refreshCareTeam = useRefreshCareTeam(dispatch);

  const { WithData, data } = useData([
    'careTeam_v3',
    'insurance',
    'patientData',
    'appointments_v2',
    'selfPayRates',
  ]);

  if (pageState.path !== 'appointments') {
    return null;
  }

  return (
    <WithData data={data}>
      {({
        careTeam_v3,
        insurance,
        patientData,
        appointments_v2,
        selfPayRates,
      }) => {
        const { payment_method, network_name, carrier } = insurance;
        const npi = Number(providerNpi);
        const provider = getProviderFromCareTeamByNpi(npi, careTeam_v3.active);
        const newAppointmentUnixNum = Number(newAppointmentUnix);
        const currentAppointment = appointments_v2.rows.find(
          (appointment) =>
            appointment.ref === `scheduling/appointments/${appointmentUUID}`,
        );
        const [therapyType, setTherapyType] = useState(
          (currentAppointment?.therapy_type || '') as TherapyType | '',
        );

        if (!providerNpi || Number.isNaN(npi) || !provider) {
          console.error('invalid provider npi');
          return <RedirectToAppointmentsOnError />;
        }

        const getButtonState = () => {
          if (waiting) {
            return 'waiting';
          }
          if (therapyType === '') {
            return 'disabled';
          }
          return '';
        };

        const { first_name, last_name, role, insurances } = provider;

        const providerName = `${first_name} ${last_name}`;

        if (!currentAppointment) {
          console.error('unable to locate appointment');
          return <RedirectToAppointmentsOnError />;
        }

        const isHold =
          currentAppointment?.status === 'held' ||
          currentAppointment?.status === 'deleted';

        if (
          !appointmentUUID ||
          !newAppointmentUnixNum ||
          Number.isNaN(newAppointmentUnixNum)
        ) {
          console.error('appointment slots missing or not provided');
          return <RedirectToAppointmentsOnError />;
        }

        const newAppointment = dayjs.unix(newAppointmentUnixNum);
        if (!newAppointment || newAppointment.isBefore(dayjs())) {
          console.error('appointment slot dates are invalid');
          return <RedirectToAppointmentsOnError />;
        }

        const bookableSlots = getBookableSlotsForProvider(
          state.ui.usedAvailabilitySlots,
          provider,
          currentAppointment.therapy_type,
          currentAppointment.series_type,
          isHold,
        );

        if (!bookableSlots.includes(newAppointmentUnixNum)) {
          console.error('selected slot unavailable');
          return <RedirectToAppointmentsOnError />;
        }

        const {
          end_time,
          start_time,
          series_type,
          status,
          provider_ref,
          participants,
        } = currentAppointment as AppointmentV2;
        const appointmentInNextDay = appointmentIsWithin24Hours(start_time);
        const isFirstAppointment = series_type === 'initial';
        const showTherapyTypePicker = !therapyType;

        const appointmentType =
          therapyType && therapyTypeToVisitType[therapyType];

        const getCashPayNumber = () => {
          if (role === psychiatryProviderRole) {
            return selfPayRates.data.psych_initial;
          }
          return therapyType === 'individual'
            ? selfPayRates.data.individual
            : selfPayRates.data.couples_family;
        };

        const cashPayAmount = makeDollars(getCashPayNumber());

        const { appointmentLengthInMinutes, newEndTime } =
          getAppointmentLengthAndEndTime(
            start_time,
            end_time,
            newAppointmentUnixNum,
          );
        const format = 'YYYY-MM-DD HH:mm:ssZ';

        const trackRescheduleEvent = (
          name: string,
          trigger: Trigger,
          metadata: { [key: string]: JSONValue } = {},
        ) => {
          const event: AnalyticsEvent = {
            product_area: 'Appointments',
            name,
            trigger,
            metadata: {
              appointment_id: appointmentUUID,
              appointment_type: appointmentType || null,
              appointment_status: status === 'held' ? 'hold' : 'appointment',
              appointment_duration_mins: appointmentLengthInMinutes,
              provider_name: providerName,
              provider_npi: providerNpi,
              treatment_type:
                appointmentType && appointmentType.includes('psychiatry')
                  ? 'psychiatry'
                  : 'therapy',
              within_late_cancel_window: appointmentInNextDay,
              ...metadata,
            },
          };
          trackEvent(event);
        };

        useEffect(() => {
          trackRescheduleEvent(
            'confirm_new_appointment_page_view',
            'Page load',
          );
        }, []);

        const getEndpointAndBody = () => {
          const commonBody: JSONObject = {
            patient_record_uuid: patientData.patient_record_uuid,
            start_time: dayjs.unix(newAppointmentUnixNum).format(format),
            end_time: newEndTime?.format(format) || '',
            is_hold: isHold,
            is_initial_appointment: isFirstAppointment,
            appointment_type:
              therapyTypeToAppointmentType[therapyType as TherapyType] || null,
            original_start_time: dayjs(start_time).toISOString(),
            appointment_uuid: appointmentUUID,
            provider_npi: providerNpi,
            therapy_type: therapyType || null,
          };
          const bodyForCreation = {
            provider_ref,
            patient_ref: getPatientFromParticipants(participants)?.ref || '',
            series_type,
          };

          let body = commonBody;
          let endpoint = '/api/reschedule_v2';
          if (
            currentAppointment.status === 'canceled' ||
            currentAppointment.status === 'deleted' ||
            appointmentIsPast(currentAppointment.end_time)
          ) {
            body = { ...commonBody, ...bodyForCreation };
            endpoint = '/api/create_appointment';
          }

          return { body, endpoint };
        };

        const onSubmit = () => {
          if (!therapyType) {
            return;
          }
          trackRescheduleEvent(
            'confirm_reschedule_appointment_button_clicked',
            'Interaction',
          );
          setWaiting(true);

          const { body, endpoint } = getEndpointAndBody();
          return fetch
            .json(endpoint, {
              method: 'POST',
              body,
            })
            .then(() => {
              trackRescheduleEvent(
                'reschedule_appointment_successful',
                'Interaction',
                {
                  old_appointment_start_time: dayjs(start_time).format(
                    'YYYY-MM-DDTHH:mm:ssZ',
                  ),
                  new_appointment_start_time: newAppointment.format(
                    'YYYY-MM-DDTHH:mm:ssZ',
                  ),
                },
              );
              setWaiting(false);
              refreshCareTeam();
              dispatch(
                actions.addUsedAvailabilitySlot({
                  npi,
                  slot: newAppointmentUnixNum,
                }),
              );
              navigate('/appointments');
              dispatch(
                actions.setToast({
                  text: (
                    <Text.P.Inline>
                      <Text.P.Inline.Bold>Success! </Text.P.Inline.Bold>Your
                      appointment has been rescheduled, and your provider has
                      been notified.{' '}
                      {isHold
                        ? 'Your list of appointments will update shortly.'
                        : null}
                    </Text.P.Inline>
                  ),
                  variant: 'success',
                  onClose: () => dispatch(actions.setToast(null)),
                }),
              );
            })
            .catch((r) => {
              let errorMessage = <>{toastDidntWorkTryAgain}</>;
              let destinationRoute = `/appointments/reschedule/${providerNpi}/${appointmentUUID}`;
              let variant: ToastProps['variant'] = 'warning';

              r.json().then(({ message }: { message: string }) => {
                if (
                  errorsFindingOldAppointment.find((errorText) =>
                    message.includes(errorText),
                  )
                ) {
                  destinationRoute = '/appointments';
                } else if (message.includes(errorSlotNotAvailable)) {
                  errorMessage = <>{toastSessionNotAvailable}</>;
                  dispatch(
                    actions.addUsedAvailabilitySlot({
                      npi,
                      slot: newAppointmentUnixNum,
                    }),
                  );
                } else if (message.includes(errorCreatingHold)) {
                  errorMessage = <>{toastErrorBookingSession}</>;
                } else if (
                  errorsCancelingHold.find((holdCancelText) =>
                    message.includes(holdCancelText),
                  )
                ) {
                  errorMessage = (
                    <>
                      We've successfully booked your new session! Your old
                      appointment may continue to appear on the appointments
                      page for a short time. Please contact <SupportLink /> if
                      you have any questions
                    </>
                  );
                  destinationRoute = '/appointments';
                  variant = 'info';
                }

                setWaiting(false);
                refreshCareTeam();
                navigate(destinationRoute);
                dispatch(
                  actions.setToast({
                    text: <Text.P>{errorMessage}</Text.P>,
                    variant: variant,
                    onClose: () => dispatch(actions.setToast(null)),
                  }),
                );

                return Promise.reject();
              });
            });
        };

        return (
          <div className="space-y-10">
            <ConfirmAppointmentPageHeader />

            {/* Body */}
            <div className="max-w-[715px] mx-auto">
              <div className="border-1 border-tertiary-2 rounded-2 px-6 py-8 mb-6">
                <ConfirmAppointmentCardHeader
                  provider={provider}
                  newAppointment={newAppointment}
                  durationInMinutes={appointmentLengthInMinutes}
                />
                <div className="pt-4 justify-center items-center space-y-4">
                  {showTherapyTypePicker ? (
                    <div className="flex items-center justify-between">
                      <div className="flex gap-2">
                        <User className="grow-0 shrink-0" />
                        <Text.P>Select therapy type</Text.P>
                      </div>
                      <div className="w-36">
                        <Dropdown
                          value={therapyType}
                          options={THERAPY_TYPE_OPTIONS}
                          onChange={(value) =>
                            setTherapyType(value as TherapyType)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex gap-2">
                      <TherapyTypeText
                        isFirstAppointment={isFirstAppointment}
                        therapyType={therapyType}
                        appointmentType={appointmentType}
                      />
                    </div>
                  )}
                  <div className="flex gap-2">
                    <Dollar className="grow-0 shrink-0" />
                    <PaymentText
                      paymentMethod={payment_method}
                      therapyType={therapyType}
                      cashPayAmount={cashPayAmount}
                      insurances={insurances}
                      networkName={network_name}
                      carrier={carrier}
                    />
                  </div>
                </div>
              </div>
              <Button
                variant="primary"
                onClick={onSubmit}
                state={getButtonState()}
              >
                Reschedule appointment
              </Button>
            </div>
          </div>
        );
      }}
    </WithData>
  );
};

export default ConfirmAppointmentV2;
