import InfoCircle from '@/components/icons/InfoCircle';
import { BillingEstimates } from '@/utils/types';

interface CashPayTemplateProps {
  billingEstimates: BillingEstimates;
}

export const CashPayTemplate = ({ billingEstimates }: CashPayTemplateProps) => {
  return (
    <div className="space-y-4">
      <div className="font-medium text-lg text-tertiary-5 flex items-center gap-2.5">
        <InfoCircle />
        No insurance on file
      </div>
      <div className="flex gap-6">
        <div className="bg-tertiary-1 rounded-2 px-9 py-6 space-y-4 min-w-[328px]">
          <div className="text-xs text-tertiary-5">COST ESTIMATE</div>
          <div>
            <div className="text-sm text-tertiary-5">Cash pay</div>
            <div className="text-primary-3 text-2xl font-medium">
              ${billingEstimates.pre_deductible_estimate}
            </div>
          </div>
        </div>
        <div className="pl-9 pr-6 text-tertiary-5">
          You can add your insurance above, and our team will update your cost
          estimate if your plan is in-network.
        </div>
      </div>
    </div>
  );
};
