import React, { Ref } from 'react';

export interface SectionProps {
  children: JSX.IntrinsicElements['div']['children'];
  fullWidth?: boolean;
}

const Section = React.forwardRef(
  ({ children, fullWidth = false }: SectionProps, ref: Ref<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        className={`p-6 border-1 border-tertiary-2 rounded w-full text-tertiary-7 space-y-5 ${!fullWidth ? 'max-w-[572px]' : ''}`}
      >
        {children}
      </div>
    );
  },
);

export default Section;
