import WithPageState from '@/pages/WithPageState';
import AvailabilityPicker from '@/components/AvailabilityPicker';
import { useContext, useState } from 'react';
import { StateContext } from '@/App';
import actions from '@/state/actions';
import { Availability } from '@/utils/types';
import Button from '@/components/Button';
import { daysOfWeek } from '@/utils/constants';

const availabilitySummary = (availability: Availability | undefined) => {
  const divider = '•';
  const getDaySummary = () => {
    if (
      !availability ||
      availability.days === 'any' ||
      !availability.days.length
    ) {
      return 'Any days';
    }
    if (availability.days.length > 4) {
      return 'Multiple days';
    }
    return daysOfWeek.filter((d) => availability.days.includes(d)).join(', ');
  };

  const preferredTimes = new Set(
    Object.values(availability?.preferredTimes ?? []).flat(),
  );

  const getSlotSummary = () => {
    if (
      !availability ||
      availability.preferredTimes === 'any' ||
      !preferredTimes.size
    ) {
      return 'Any times of day';
    }
    if (preferredTimes.size === 1) {
      return [...preferredTimes][0];
    }
    return 'Multiple times';
  };

  return `${getDaySummary()} ${divider} ${getSlotSummary()}`;
};

const AvailabilityPickerWrapper = ({
  availability,
  onChange,
  onClose,
}: {
  availability: Availability | undefined;
  onChange: (availability: Availability | undefined) => unknown;
  onClose: () => unknown;
}) => {
  const [pendingAvailability, setPendingAvailability] = useState(availability);
  return (
    <AvailabilityPicker
      value={pendingAvailability}
      onChange={setPendingAvailability}
      onClose={() => {
        onChange(pendingAvailability);
        onClose();
      }}
    />
  );
};

const AvailabilityPickerButton = () => {
  const { dispatch } = useContext(StateContext);
  return (
    <WithPageState page="care-team">
      {({ pageState }) => {
        const { availability, availabilityPickerOpen } = pageState.rematch;
        return (
          <div className="relative">
            <Button
              onClick={() =>
                dispatch(
                  actions.careTeam.setAvailabilityPickerOpen(
                    !availabilityPickerOpen,
                  ),
                )
              }
            >
              {availabilitySummary(availability)}
            </Button>
            <div
              className={`${
                availabilityPickerOpen ? 'block' : 'hidden'
              } absolute top-14 left-0 right-0 md:right-[unset] md:min-w-[19rem] z-[9]`}
            >
              <AvailabilityPickerWrapper
                availability={availability}
                onChange={(payload) =>
                  dispatch(actions.careTeam.setAvailability(payload))
                }
                onClose={() =>
                  dispatch(actions.careTeam.setAvailabilityPickerOpen(false))
                }
              />
            </div>
          </div>
        );
      }}
    </WithPageState>
  );
};

export default AvailabilityPickerButton;
