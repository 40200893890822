import Button from '@/components/Button';
import Text from '@/components/Text';
import Avatar from '@/components/Avatar';
import InfoCircle from '@/components/icons/InfoCircle';
import InfoCard from '@/components/InfoCard';
import X from '@/components/icons/X';

// In practice, minor_13_17 is not a valid access_type here since we would never show the account
// picker to a minor
interface AccountCardProps {
  patientRecordUuid: string;
  isExpired?: boolean;
  relationship?:
    | 'self'
    | 'parent_guardian'
    | 'medical_guardian'
    | 'minor_13_17';
  firstName: string;
  lastName: string;
  preferredName: string;
  onCloseExpiredClick?: (patientRecordUuid: string) => void;
  onClick?: (patientRecordUuid: string) => void;
}

const AccountCard = ({
  patientRecordUuid,
  relationship,
  firstName,
  lastName,
  preferredName,
  onClick,
  onCloseExpiredClick,
  isExpired,
}: AccountCardProps) => {
  const getButtonText = () => {
    if (isExpired) {
      return 'Expired';
    }
    if (relationship === 'self') {
      return 'Sign in';
    }
    return 'Parent/guardian sign in';
  };
  const avatarColor = relationship === 'self' ? 'secondary-3' : undefined;
  const initials = (preferredName[0] || firstName[0]) + lastName[0];

  return (
    <div className="relative border-tertiary-2 border-1 rounded-2 w-[275px] h-[240px] flex flex-col m-2.5 items-center bg-white justify-around group">
      {isExpired ? (
        <div className="sm:invisible group-hover:visible">
          <X
            className="absolute right-[22px] top-[22px] cursor-pointer peer z-10"
            onClick={() => onCloseExpiredClick?.(patientRecordUuid)}
          />
          <div className="hidden absolute z-10 top-[-40px] right-[-75px] text-tertiary-5 peer-hover:block">
            <InfoCard>
              <Text.P>Remove user from view.</Text.P>
            </InfoCard>
          </div>
        </div>
      ) : null}
      <div
        className={`mt-9 mb-5 flex flex-col items-center ${
          isExpired ? 'opacity-50' : null
        }`}
      >
        <Avatar.Large initials={initials} color={avatarColor} />
        <Text.H3 className="mt-2 px-3 text-overflow-ellipsis line-clamp-2">
          {preferredName || firstName} {lastName}
        </Text.H3>
      </div>

      <div className="mb-[36px]">
        <Button
          size="small"
          variant="primary-outline"
          onClick={() => onClick?.(patientRecordUuid)}
          sizeClasses="w-[235px]"
          state={isExpired ? 'disabled' : undefined}
          renderIcon={
            isExpired
              ? () => (
                  <div className="hover:text-tertiary-5">
                    <InfoCircle size={20} className="cursor-pointer peer" />
                    <div className="hidden absolute z-10 top-[-90px] left-[-140px] w-[356px] peer-hover:block">
                      <InfoCard>
                        <Text.P>
                          You previously managed care for this child who reached
                          the legal age of majority.
                        </Text.P>
                      </InfoCard>
                    </div>
                  </div>
                )
              : undefined
          }
        >
          {getButtonText()}
        </Button>
      </div>
    </div>
  );
};

export const NewAccountCard = ({
  onAddAccountClick,
}: {
  onAddAccountClick: () => void;
}) => {
  return (
    <div className="border-tertiary-2 border-1 rounded-2 w-[275px] h-[240px] flex flex-col m-2.5 items-center bg-white justify-around">
      <div className="mt-9 mb-5 flex flex-col items-center">
        <Avatar.Large />
        <Text.P.Bold className="mt-2 px-5">
          Link a profile for someone whose care you manage
        </Text.P.Bold>
      </div>
      <div className="mb-[36px]">
        <Button
          size="small"
          variant="primary-outline"
          onClick={onAddAccountClick}
          sizeClasses="w-[235px]"
        >
          Link Profile
        </Button>
      </div>
    </div>
  );
};

export default AccountCard;
