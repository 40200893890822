import Icon, { IconProps } from './Icon';

const Phone = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M10.25 9H5.45C4.32989 9 3.76984 9 3.34202 9.21799C2.96569 9.40973 2.65973 9.71569 2.46799 10.092C2.25 10.5198 2.25 11.0799 2.25 12.2V19M20.25 19V4.2C20.25 3.0799 20.25 2.51984 20.032 2.09202C19.8403 1.71569 19.5343 1.40973 19.158 1.21799C18.7302 1 18.1701 1 17.05 1H13.45C12.3299 1 11.7698 1 11.342 1.21799C10.9657 1.40973 10.6597 1.71569 10.468 2.09202C10.25 2.51984 10.25 3.0799 10.25 4.2V19M21.25 19H1.25M13.75 5H16.75M13.75 9H16.75M13.75 13H16.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default Phone;
