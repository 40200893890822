import { TherapyType } from '@/utils/types';
import ReplaceProviderTrigger from './ReplaceProviderTrigger';
import Text from '@/components/Text';
import stopPropagation from '@/utils/stop-propagation';

const ReplaceProviderButtonV2 = ({
  providerNpi,
  therapyType,
}: {
  providerNpi: number;
  therapyType: TherapyType;
}) => {
  return (
    <ReplaceProviderTrigger providerNpi={providerNpi} therapyType={therapyType}>
      {({ onClick }) => (
        <Text.P
          role="button"
          onClick={stopPropagation((e) => {
            e.preventDefault();
            onClick();
          })}
        >
          Replace provider
        </Text.P>
      )}
    </ReplaceProviderTrigger>
  );
};

export default ReplaceProviderButtonV2;
