import { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MobileContext, StateContext } from '@/App';
import Dropdown from '@/components/Dropdown';
import RadioButton from '@/components/RadioButton';
import Text from '@/components/Text';
import TextInput from '@/components/TextInput';
import actions from '@/state/actions';
import {
  CarrierOption,
  EditableInsuranceDetails,
  InsurancePageState,
  ProfilePageState,
  SexOnInsuranceCard,
} from '@/utils/types';
import { makeErrorState, ValidationError } from '@/utils/use-validation';
import DateInput from '@/components/DateInput';
import InfoCircle from '@/components/icons/InfoCircle';
import InfoCard from '@/components/InfoCard';
import stopPropagation from '@/utils/stop-propagation';
import { usStateAbbreviations } from '@/utils/constants';
import { useFlagCheck } from '@/utils/use-feature-flags';

const genderOptions: Array<{ key: string; value: 'Male' | 'Female' }> = (
  ['Male', 'Female'] as const
).map((g) => ({ key: g, value: g }));

const Dropzone = ({
  onUpload,
  testId,
}: {
  onUpload: (file: File | undefined) => unknown;
  testId?: string;
}) => {
  const [state, setState] = useState<
    { type: 'success' } | { type: 'error'; error: string } | { type: 'init' }
  >({ type: 'init' });
  const [filename, setFilename] = useState<string | undefined>(undefined);
  const onError = (error: string) => {
    setState({
      type: 'error',
      error,
    });
    onUpload(undefined);
    setFilename(undefined);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length !== 1) {
      onError('Please upload a single file at a time');
      return;
    }
    if (
      ![
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'application/pdf',
      ].includes(acceptedFiles[0].type)
    ) {
      onError('Invalid file type (.png, .jpg, .jpeg, .gif, or .pdf required)');
      return;
    }
    if (acceptedFiles[0].size > 5000000) {
      onError('Please upload a file smaller than 5MB');
      return;
    }

    setState({ type: 'success' });
    const file = acceptedFiles[0];
    onUpload(file);
    setFilename(file.name);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const getDropZoneDivClassNames = () => {
    switch (state.type) {
      case 'error':
        return 'border-warning-1 bg-warning-0 text-warning-1';
      case 'success':
        return 'border-secondary-3 bg-secondary-0 text-secondary-4';
      default:
        return 'border-primary-3 bg-primary-0 text-primary-4';
    }
  };

  const getDropZoneText = () => {
    if (filename) {
      return filename;
    }

    return state.type === 'error'
      ? state.error
      : 'Drag and drop or click to upload';
  };

  return (
    <div
      className={`h-44 border-dashed rounded-2 border-1 flex justify-center items-center cursor-copy ${getDropZoneDivClassNames()}`}
      {...getRootProps()}
    >
      <input {...getInputProps()} data-testid={testId} />
      <Text.P className="text-primary-4">{getDropZoneText()}</Text.P>
    </div>
  );
};

export const InsuranceForm = ({
  editState,
  carriers,
  pageState,
  validation,
}: {
  editState: {
    edited: EditableInsuranceDetails;
    invalid?: boolean | undefined;
  };
  carriers: CarrierOption[];
  pageState: ProfilePageState | InsurancePageState;
  validation: ValidationError[];
}) => {
  const { isAllowed } = useFlagCheck();
  const effectiveDates = isAllowed({ patientPortalEffectiveDates: true });
  const { dispatch } = useContext(StateContext);

  const {
    relationship_to_insured,
    subscriber,
    subscriber_id,
    carrier,
    sex_on_insurance_card,
    effective_date,
  } = editState.edited;

  const errorState = makeErrorState(!!editState.invalid, validation);

  const mobile = useContext(MobileContext);

  return (
    <>
      <div>
        <Text.P className="mb-1">
          Who is the named person on the insurance policy?
        </Text.P>
        <div className="flex flex-wrap gap-4">
          <RadioButton
            label="Self"
            name="insured"
            selectedValue={relationship_to_insured ?? ''}
            value="Self"
            onCheck={() => {
              dispatch(
                actions.profile.setEditedInsurance({
                  relationship_to_insured: 'Self',
                }),
              );
            }}
          />
          <RadioButton
            label="Spouse"
            name="insured"
            selectedValue={relationship_to_insured ?? ''}
            value="Spouse"
            onCheck={() => {
              dispatch(
                actions.profile.setEditedInsurance({
                  relationship_to_insured: 'Spouse',
                }),
              );
            }}
          />
          <RadioButton
            label="Other"
            name="insured"
            selectedValue={relationship_to_insured ?? ''}
            value="Other"
            onCheck={() => {
              dispatch(
                actions.profile.setEditedInsurance({
                  relationship_to_insured: 'Other',
                }),
              );
            }}
          />
          {relationship_to_insured && relationship_to_insured !== 'Self' && (
            <div className="border-t-1 border-b-1 border-tertiary-2 pt-4 pb-4 flex flex-wrap w-full gap-2">
              <Text.P.Bold className="text-tertiary-6 basis-full space-y-1">
                Responsible party's information
              </Text.P.Bold>
              <div className="basis-full space-y-1 md:basis-[calc(50%-4px)]">
                <Text.P>First name</Text.P>
                <TextInput
                  state={errorState('first_name')}
                  value={subscriber.first_name}
                  onChange={(v) =>
                    dispatch(
                      actions.profile.setEditedInsurance({
                        subscriber: {
                          ...subscriber,
                          first_name: v,
                        },
                      }),
                    )
                  }
                />
              </div>
              <div className="basis-full space-y-1 md:basis-[calc(50%-4px)]">
                <Text.P>Last name</Text.P>
                <TextInput
                  state={errorState('last_name')}
                  value={subscriber.last_name}
                  onChange={(v) =>
                    dispatch(
                      actions.profile.setEditedInsurance({
                        subscriber: {
                          ...subscriber,
                          last_name: v,
                        },
                      }),
                    )
                  }
                />
              </div>
              <div className="basis-full space-y-1 md:basis-[calc(66%-4px)]">
                <Text.P>Date of birth</Text.P>
                <DateInput
                  state={errorState('birthdate')}
                  value={subscriber.birthdate}
                  placeholder="MM/DD/YYYY"
                  onChange={(v) =>
                    dispatch(
                      actions.profile.setEditedInsurance({
                        subscriber: {
                          ...subscriber,
                          birthdate: v,
                        },
                      }),
                    )
                  }
                />
              </div>
              <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
                <div className="flex gap-1 items-center">
                  <Text.P>Sex at birth</Text.P>
                  <div className="w-5 h-5 cursor-pointer">
                    <InfoCircle.Small
                      onClick={stopPropagation(() =>
                        dispatch(
                          actions.profile.setSexAtBirthTooltipOpen(
                            !pageState.sexAtBirthTooltipOpen,
                          ),
                        ),
                      )}
                      onMouseEnter={
                        mobile
                          ? undefined
                          : () =>
                              dispatch(
                                actions.profile.setSexAtBirthTooltipOpen(true),
                              )
                      }
                    />
                  </div>
                </div>
                {pageState.sexAtBirthTooltipOpen && (
                  <div
                    onMouseLeave={
                      mobile
                        ? undefined
                        : () =>
                            dispatch(
                              actions.profile.setSexAtBirthTooltipOpen(false),
                            )
                    }
                    className="flex-grow md:absolute md:left-[75px] md:w-max mb-2 md:mb-0 items-center"
                  >
                    <InfoCard>
                      Insurance companies require the collection of "sex on your
                      insurance card" for billing purposes. Rula recognizes the
                      diversity of gender identity and expression.
                    </InfoCard>
                  </div>
                )}
                <Dropdown
                  state={errorState('gender')}
                  options={genderOptions}
                  value={subscriber.gender}
                  onChange={(v) =>
                    dispatch(
                      actions.profile.setEditedInsurance({
                        subscriber: {
                          ...subscriber,
                          gender: v,
                        },
                      }),
                    )
                  }
                />
              </div>
              <div className="basis-full space-y-1 md:basis-[calc(66%-4px)]">
                <Text.P>Street address</Text.P>
                <TextInput
                  state={errorState('street')}
                  value={subscriber.address.street}
                  onChange={(v) =>
                    dispatch(
                      actions.profile.setEditedInsurance({
                        subscriber: {
                          ...subscriber,
                          address: { ...subscriber.address, street: v },
                        },
                      }),
                    )
                  }
                />
              </div>
              <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
                <Text.P>Apt #</Text.P>
                <TextInput
                  value={subscriber.address.apt_suite}
                  onChange={(v) =>
                    dispatch(
                      actions.profile.setEditedInsurance({
                        subscriber: {
                          ...subscriber,
                          address: {
                            ...subscriber.address,
                            apt_suite: v,
                          },
                        },
                      }),
                    )
                  }
                />
              </div>
              <div className="basis-full space-y-1 md:basis-[calc(66%-4px)]">
                <Text.P>City</Text.P>
                <TextInput
                  state={errorState('city')}
                  value={subscriber.address.city}
                  onChange={(v) =>
                    dispatch(
                      actions.profile.setEditedInsurance({
                        subscriber: {
                          ...subscriber,
                          address: { ...subscriber.address, city: v },
                        },
                      }),
                    )
                  }
                />
              </div>
              <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
                <Text.P>State</Text.P>
                {/* height of this dropdown needs to subtract the Text.P height from the parent's height */}
                <div className="h-[calc(100%-24px)]">
                  <Dropdown
                    state={errorState('state')}
                    value={subscriber.address.state}
                    onChange={(v) =>
                      dispatch(
                        actions.profile.setEditedInsurance({
                          subscriber: {
                            ...subscriber,
                            address: {
                              ...subscriber.address,
                              state: v,
                            },
                          },
                        }),
                      )
                    }
                    options={usStateAbbreviations.map((v) => ({
                      key: v,
                      value: v,
                    }))}
                  />
                </div>
              </div>
              <div className="basis-full space-y-1">
                <Text.P>Zip code</Text.P>
                <TextInput
                  state={errorState('zip')}
                  value={subscriber.address.zip}
                  onChange={(v) =>
                    dispatch(
                      actions.profile.setEditedInsurance({
                        subscriber: {
                          ...subscriber,
                          address: { ...subscriber.address, zip: v },
                        },
                      }),
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Text.P.Bold>Insurance details</Text.P.Bold>
      <div className="space-y-1">
        <Text.P>Insurance provider</Text.P>
        <Dropdown
          state={errorState('carrier')}
          options={carriers.map(({ label, value }) => ({
            key: label,
            value: value,
          }))}
          value={carrier}
          onChange={(v) =>
            dispatch(actions.profile.setEditedInsurance({ carrier: v }))
          }
        />
      </div>
      <div className="space-y-1">
        <Text.P>Subscriber ID / Member ID</Text.P>
        <TextInput
          state={errorState('subscriber_id')}
          value={subscriber_id}
          onChange={(v) =>
            dispatch(actions.profile.setEditedInsurance({ subscriber_id: v }))
          }
        />
      </div>
      <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
        <div className="flex gap-1 items-center">
          <Text.P>Patient sex on your insurance card</Text.P>
          <div className="w-5 h-5 cursor-pointer">
            <InfoCircle.Small
              onClick={stopPropagation(() =>
                dispatch(
                  actions.profile.setSexOnInsuranceCardTooltipOpen(
                    !pageState.sexOnInsuranceCardTooltipOpen,
                  ),
                ),
              )}
              onMouseEnter={
                mobile
                  ? undefined
                  : () =>
                      dispatch(
                        actions.profile.setSexOnInsuranceCardTooltipOpen(true),
                      )
              }
            />
          </div>
        </div>
        {pageState.sexOnInsuranceCardTooltipOpen && (
          <div
            onMouseLeave={
              mobile
                ? undefined
                : () =>
                    dispatch(
                      actions.profile.setSexOnInsuranceCardTooltipOpen(false),
                    )
            }
            className="flex-grow md:absolute md:left-[75px] md:w-max mb-2 md:mb-0 items-center"
          >
            <InfoCard>
              Insurance companies require the collection of "sex on your
              insurance card" for billing purposes. Rula recognizes the
              diversity of gender identity and expression.
            </InfoCard>
          </div>
        )}
        <div
          className={`flex flex-wrap gap-4 ${
            errorState('sex_on_insurance_card') === 'error'
              ? 'border-1 border-warning-1 rounded'
              : ''
          }`}
        >
          {['Male', 'Female'].map((sexOnInsuranceCard) => {
            return (
              <RadioButton
                key={sexOnInsuranceCard}
                label={sexOnInsuranceCard}
                name="sex_on_insurance_card"
                selectedValue={sex_on_insurance_card ?? ''}
                value={sexOnInsuranceCard}
                onCheck={() =>
                  dispatch(
                    actions.profile.setEditedInsurance({
                      sex_on_insurance_card:
                        sexOnInsuranceCard as SexOnInsuranceCard,
                    }),
                  )
                }
              />
            );
          })}
        </div>
      </div>
      {effectiveDates && (
        <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
          <div className="flex gap-1 items-center">
            <Text.P>Coverage start date</Text.P>

            <div className="w-5 h-5 cursor-pointer">
              <InfoCircle.Small
                onClick={stopPropagation(() =>
                  dispatch(
                    actions.profile.setEffectiveDateTooltipOpen(
                      !pageState.effectiveDateTooltipOpen,
                    ),
                  ),
                )}
                onMouseEnter={
                  mobile
                    ? undefined
                    : () =>
                        dispatch(
                          actions.profile.setEffectiveDateTooltipOpen(true),
                        )
                }
              />
            </div>
          </div>
          {pageState.effectiveDateTooltipOpen && (
            <div
              onMouseLeave={
                mobile
                  ? undefined
                  : () =>
                      dispatch(
                        actions.profile.setEffectiveDateTooltipOpen(false),
                      )
              }
              className="flex-grow md:absolute md:left-[75px] md:w-max mb-2 md:mb-0 items-center"
            >
              <InfoCard>
                Your coverage effective date is when your policy starts or
                started with your insurance carrier. Most policies start or
                renew on the first of the year. You should be able to find the
                coverage start date on your insurance card or on your insurance
                provider's website.
              </InfoCard>
            </div>
          )}
          <DateInput
            state={errorState('effective_date')}
            value={effective_date}
            placeholder="MM/DD/YYYY"
            onChange={(v) =>
              dispatch(
                actions.profile.setEditedInsurance({
                  effective_date: v,
                }),
              )
            }
          />
        </div>
      )}
      <div className="space-y-1">
        <Text.P>Upload a photo of the front of your insurance card</Text.P>
        <Dropzone
          testId="insurance-front-of-card"
          onUpload={(f) => dispatch(actions.profile.setInsuranceFrontOfCard(f))}
        />
      </div>
      <div className="space-y-1">
        <Text.P>Upload a photo of the back of your insurance card</Text.P>
        <Dropzone
          testId="insurance-back-of-card"
          onUpload={(f) => dispatch(actions.profile.setInsuranceBackOfCard(f))}
        />
      </div>
    </>
  );
};
